import React from 'react';
import './PackageIncluded.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Tirana from '../../assets/features/tirana.jpg';
import airplane from '../../assets/features/icons/airplane.svg';
import camera from '../../assets/features/icons/camera.svg';
import luggage from '../../assets/features/icons/luggage.svg';
import map from '../../assets/features/icons/map.svg';
import priceTag from '../../assets/features/icons/price-tag.svg';
import translate from '../../assets/features/icons/translate.svg';
import { useTranslation } from "react-i18next";


const PackageIncluded = () => {
    const { t } = useTranslation();

    return (
        <section className='package-included-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="tirana-img">
                            <img src={Tirana} alt="Tirana" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="package-included-text">
                            <SectionTitle
                                title={t('components.home.including.title')}
                                description={t('components.home.including.description')}
                            />

                            <div className='free-packages'>
                                <div className='package'>
                                    <img src={map} alt='map icon' />
                                    <span>{t('components.home.including.guide')}</span>
                                </div>
                                <div className='package'>
                                    <img src={airplane} alt='airplane icon' />
                                    <span>{t('components.home.including.ticket')}</span>
                                </div>
                                <div className='package'>
                                    <img src={luggage} alt='luggage icon' />
                                    <span>{t('components.home.including.accommodation')}</span>
                                </div>
                                <div className='package'>
                                    <img src={camera} alt='camera icon' />
                                    <span>{t('components.home.including.tourist')}</span>
                                </div>
                                <div className='package'>
                                    <img src={translate} alt='translate icon' />
                                    <span>{t('components.home.including.translator')}</span>
                                </div>
                                <div className='package'>
                                    <img src={priceTag} alt='price tag icon' />
                                    <span>{t('components.home.including.overnight')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PackageIncluded;