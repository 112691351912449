export const services = [
  { id: 1, label: "components.navbar.services.implantology" },
  { id: 2, label: "components.navbar.services.cosmeticDentistry" },
  { id: 3, label: "components.navbar.services.surgeries" },
  { id: 4, label: "components.service.endodontic" },
  { id: 5, label: "components.service.prosthodontic.prosthodontic" },
];

export const navbarItems = [
  {
    name: "components.navbar.home",
    path: "/",
  },
  {
    name: "components.navbar.about.us",
    path: "/about",
  },
  {
    name: "components.navbar.services",
    submenu: [
      {
        name: "components.navbar.services.implantology",
        path: "/implantology",
        submenu: [
          {
            id: "allInFour",
            name: "components.service.implantology.allInFour",
            path: "/allInFour",
          },
          {
            id: "allInSix",
            name: "components.service.implantology.allInSix",
            path: "/allInSix",
          },
          {
            id: "allInEight",
            name: "components.service.implantology.allInEight",
            path: "/allInEight",
          },
          {
            id: "dentalImplants",
            name: "components.service.implantology.dentalImplants",
            path: "/dentalImplants",
          },
        ],
      },
      {
        name: "components.navbar.services.cosmeticDentistry",
        path: "/cosmeticDentistry",
        submenu: [
          {
            id: "veneers",
            name: "components.service.cosmeticDentistry.veneers",
            path: "/veneers",
          },
          {
            id: "bleaching",
            name: "components.service.cosmeticDentistry.bleaching",
            path: "/bleaching",
          },
          {
            id: "filling",
            name: "components.service.cosmeticDentistry.filling",
            path: "/filling",
          },
          {
            id: "cleaning",
            name: "components.service.cosmeticDentistry.cleaning",
            path: "/cleaning",
          },
          {
            id: "restoration",
            name: "components.service.cosmeticDentistry.restoration",
            path: "/restoration",
          },
        ],
      },
      {
        name: "components.navbar.services.surgeries",
        path: "/surgeries",
        submenu: [
          {
            id: "oral",
            name: "components.service.surgeries.oral",
            path: "/oral",
          },
          {
            id: "gum",
            name: "components.service.surgeries.gum",
            path: "/gum",
          },
        ],
      },
      {
        name: "components.service.endodontic",
        path: "/endodontic",
        submenu: [
          {
            id: "rootCanals",
            name: "components.service.endodontic.rootCanals",
            path: "/rootCanals",
          },
        ],
      },
      {
        name: "components.navbar.services.prosthodontic",
        path: "/prosthodontic",
        submenu: [
          {
            id: "crown",
            name: "components.service.prosthodontic.crown",
            path: "/crown",
          },
          {
            id: "bridge",
            name: "components.service.prosthodontic.bridge",
            path: "/bridge",
          },
          {
            id: "prosthodontic",
            name: "components.service.prosthodontic.prosthodontic",
            path: "/prosthodontic",
          },
          {
            id: "dentures",
            name: "components.service.prosthodontic.dentures",
            path: "/dentures",
          },
        ],
      },
    ],
  },
  {
    name: "components.navbar.contact.us",
    path: "/contact",
  },
];

const allInFour = {
  id: 1,
  content: {
    image: "allInFour",
    title: "components.service.implantology.allInFour.title",
    description: "components.service.implantology.allInFour.description",
    symptoms: {
      title: "components.service.implantology.allInFour.symptoms.title",
      description:
        "components.service.implantology.allInFour.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.implantology.allInFour.symptoms.signs.1.title",
          description:
            "components.service.implantology.allInFour.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInFour.symptoms.signs.2.title",
          description:
            "components.service.implantology.allInFour.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInFour.symptoms.signs.3.title",
          description:
            "components.service.implantology.allInFour.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      marginLeft: "20px",
      paddingTop: "100px",
    },
  },
};

const allInSix = {
  id: 2,
  content: {
    image: "allInSix",
    title: "components.service.implantology.allInSix.title",
    description: "components.service.implantology.allInSix.description",
    symptoms: {
      title: "components.service.implantology.allInSix.symptoms.title",
      description:
        "components.service.implantology.allInSix.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.implantology.allInSix.symptoms.signs.1.title",
          description:
            "components.service.implantology.allInSix.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInSix.symptoms.signs.2.title",
          description:
            "components.service.implantology.allInSix.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInSix.symptoms.signs.3.title",
          description:
            "components.service.implantology.allInSix.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      marginLeft: "20px",
      paddingTop: "50px",
    },
  },
};

const allInEight = {
  id: 3,
  content: {
    image: "allInEight",
    title: "components.service.implantology.allInEight.title",
    description: "components.service.implantology.allInEight.description",
    symptoms: {
      subtitle: "Symptoms 8",
      title: "components.service.implantology.allInEight.symptoms.title",
      description:
        "components.service.implantology.allInEight.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.implantology.allInEight.symptoms.signs.1.title",
          description:
            "components.service.implantology.allInEight.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInEight.symptoms.signs.2.title",
          description:
            "components.service.implantology.allInEight.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.allInEight.symptoms.signs.3.title",
          description:
            "components.service.implantology.allInEight.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      marginLeft: "20px",
      paddingTop: "0px",
    },
  },
};

const dentalImplants = {
  id: 4,
  content: {
    image: "dentalImplants",
    title: "components.service.implantology.dentalImplants.title",
    description: "components.service.implantology.dentalImplants.description",
    symptoms: {
      title: "components.service.implantology.dentalImplants.symptoms.title",
      description:
        "components.service.implantology.dentalImplants.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.implantology.dentalImplants.symptoms.signs.1.title",
          description:
            "components.service.implantology.dentalImplants.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.implantology.dentalImplants.symptoms.signs.2.title",
          description:
            "components.service.implantology.dentalImplants.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "120px",
    },
  },
};

const rootCanals = {
  id: 5,
  content: {
    image: "rootCanals",
    title: "components.service.endodontic.rootCanals.title",
    description: "components.service.endodontic.rootCanals.description",
    symptoms: {
      title: "components.service.endodontic.rootCanals.symptoms.title",
      description:
        "components.service.endodontic.rootCanals.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.endodontic.rootCanals.symptoms.signs.1.title",
          description:
            "components.service.endodontic.rootCanals.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.endodontic.rootCanals.symptoms.signs.2.title",
          description:
            "components.service.endodontic.rootCanals.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "20px",
    },
  },
};

const veneers = {
  id: 6,
  content: {
    image: "veneers",
    title: "components.service.cosmeticDentistry.veneers.title",
    description: "components.service.cosmeticDentistry.veneers.description",
    symptoms: {
      title: "components.service.cosmeticDentistry.veneers.symptoms.title",
      description:
        "components.service.cosmeticDentistry.veneers.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.1.title",
          description:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.2.title",
          description:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.3.title",
          description:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.3.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.4.title",
          description:
            "components.service.cosmeticDentistry.veneers.symptoms.signs.4.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "20px",
    },
  },
};

const bleaching = {
  id: 7,
  content: {
    image: "bleaching",
    title: "components.service.cosmeticDentistry.bleaching.title",
    description: "components.service.cosmeticDentistry.bleaching.description",
    symptoms: {
      title: "components.service.cosmeticDentistry.bleaching.symptoms.title",
      description:
        "components.service.cosmeticDentistry.bleaching.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.1.title",
          description:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.2.title",
          description:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.3.title",
          description:
            "components.service.cosmeticDentistry.bleaching.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "40px",
    },
  },
};

const filling = {
  id: 8,
  content: {
    image: "filling",
    title: "components.service.cosmeticDentistry.filling.title",
    description: "components.service.cosmeticDentistry.filling.description",
    symptoms: {
      title: "components.service.cosmeticDentistry.filling.symptoms.title",
      description:
        "components.service.cosmeticDentistry.filling.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.filling.symptoms.signs.1.title",
          description:
            "components.service.cosmeticDentistry.filling.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.filling.symptoms.signs.2.title",
          description:
            "components.service.cosmeticDentistry.filling.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.filling.symptoms.signs.3.title",
          description:
            "components.service.cosmeticDentistry.filling.symptoms.signs.3.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.filling.symptoms.signs.4.title",
          description:
            "components.service.cosmeticDentistry.filling.symptoms.signs.4.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "250px",
    },
  },
};

const cleaning = {
  id: 9,
  content: {
    image: "cleaning",
    title: "components.service.cosmeticDentistry.cleaning.title",
    description: "components.service.cosmeticDentistry.cleaning.description",
    symptoms: {
      title: "components.service.cosmeticDentistry.cleaning.symptoms.title",
      description:
        "components.service.cosmeticDentistry.cleaning.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.cleaning.symptoms.signs.1.title",
          description:
            "components.service.cosmeticDentistry.cleaning.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.cleaning.symptoms.signs.2.title",
          description:
            "components.service.cosmeticDentistry.cleaning.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "250px",
    },
  },
};

const restoration = {
  id: 10,
  content: {
    image: "restoration",
    title: "components.service.cosmeticDentistry.restoration.title",
    description: "components.service.cosmeticDentistry.restoration.description",
    symptoms: {
      title: "components.service.cosmeticDentistry.restoration.symptoms.title",
      description:
        "components.service.cosmeticDentistry.restoration.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.1.title",
          description:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.2.title",
          description:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.2.description",
        },
        {
          icon: "",
          title:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.3.title",
          description:
            "components.service.cosmeticDentistry.restoration.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "100px",
    },
  },
};

const oral = {
  id: 11,
  content: {
    image: "oral",
    title: "components.service.surgeries.oral.title",
    description: "components.service.surgeries.oral.description",
    symptoms: {
      title: "components.service.surgeries.oral.symptoms.title",
      description: "components.service.surgeries.oral.symptoms.description",
      signs: [
        {
          icon: "",
          title: "components.service.surgeries.oral.symptoms.signs.1.title",
          description:
            "components.service.surgeries.oral.symptoms.signs.1.description",
        },
        {
          icon: "",
          title: "components.service.surgeries.oral.symptoms.signs.2.title",
          description:
            "components.service.surgeries.oral.symptoms.signs.2.description",
        },
        {
          icon: "",
          title: "components.service.surgeries.oral.symptoms.signs.3.title",
          description:
            "components.service.surgeries.oral.symptoms.signs.3.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "120px",
    },
  },
};

const gum = {
  id: 12,
  content: {
    image: "gum",
    title: "components.service.surgeries.gum.title",
    description: "components.service.surgeries.gum.description",
    symptoms: {
      title: "components.service.surgeries.gum.symptoms.title",
      description: "components.service.surgeries.gum.symptoms.description",
      signs: [
        {
          icon: "",
          title: "components.service.surgeries.gum.symptoms.signs.1.title",
          description:
            "components.service.surgeries.gum.symptoms.signs.1.description",
        },
        {
          icon: "",
          title: "components.service.surgeries.gum.symptoms.signs.2.title",
          description:
            "components.service.surgeries.gum.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "100px",
    },
  },
};

const crown = {
  id: 13,
  content: {
    image: "crown",
    title: "components.service.prosthodontic.crown.title",
    description: "components.service.prosthodontic.crown.description",
    symptoms: {
      title: "components.service.prosthodontic.crown.symptoms.title",
      description:
        "components.service.prosthodontic.crown.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.prosthodontic.crown.symptoms.signs.1.title",
          description:
            "components.service.prosthodontic.crown.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.prosthodontic.crown.symptoms.signs.2.title",
          description:
            "components.service.prosthodontic.crown.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "90px",
    },
  },
};

const bridge = {
  id: 14,
  content: {
    image: "bridge",
    title: "components.service.prosthodontic.bridge.title",
    description: "components.service.prosthodontic.bridge.description",
    symptoms: {
      title: "components.service.prosthodontic.bridge.symptoms.title",
      description:
        "components.service.prosthodontic.bridge.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.prosthodontic.bridge.symptoms.signs.1.title",
          description:
            "components.service.prosthodontic.bridge.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.prosthodontic.bridge.symptoms.signs.2.title",
          description:
            "components.service.prosthodontic.bridge.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "50px",
    },
  },
};

const prosthodontic = {
  id: 15,
  content: {
    image: "prosthodontic",
    title: "components.service.prosthodontic.prosthodontic.title",
    description: "components.service.prosthodontic.prosthodontic.description",
    symptoms: {
      title: "components.service.prosthodontic.prosthodontic.symptoms.title",
      description:
        "components.service.prosthodontic.prosthodontic.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.prosthodontic.prosthodontic.symptoms.signs.1.title",
          description:
            "components.service.prosthodontic.prosthodontic.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.prosthodontic.prosthodontic.symptoms.signs.2.title",
          description:
            "components.service.prosthodontic.prosthodontic.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "80px",
      width: "400px",
      height: "400px",
    },
  },
};

const dentures = {
  id: 16,
  content: {
    image: "dentures",
    title: "components.service.prosthodontic.dentures.title",
    description: "components.service.prosthodontic.dentures.description",
    symptoms: {
      title: "components.service.prosthodontic.dentures.symptoms.title",
      description:
        "components.service.prosthodontic.dentures.symptoms.description",
      signs: [
        {
          icon: "",
          title:
            "components.service.prosthodontic.dentures.symptoms.signs.1.title",
          description:
            "components.service.prosthodontic.dentures.symptoms.signs.1.description",
        },
        {
          icon: "",
          title:
            "components.service.prosthodontic.dentures.symptoms.signs.2.title",
          description:
            "components.service.prosthodontic.dentures.symptoms.signs.2.description",
        },
      ],
    },
    proof: {
      title: "components.service.proof.title",
      description: "components.service.proof.description",
    },
    css: {
      paddingTop: "80px",
    },
  },
};

export const findContentById = (id) => {
  switch (id) {
    case "allInFour":
      return allInFour.content;
    case "allInSix":
      return allInSix.content;
    case "allInEight":
      return allInEight.content;
    case "dentalImplants":
      return dentalImplants.content;
    case "rootCanals":
      return rootCanals.content;
    case "veneers":
      return veneers.content;
    case "bleaching":
      return bleaching.content;
    case "filling":
      return filling.content;
    case "cleaning":
      return cleaning.content;
    case "restoration":
      return restoration.content;
    case "oral":
      return oral.content;
    case "gum":
      return gum.content;
    case "crown":
      return crown.content;
    case "bridge":
      return bridge.content;
    case "prosthodontic":
      return prosthodontic.content;
    case "dentures":
      return dentures.content;
    default:
      return null;
  }
};

export const FAQ = [
  {
    id: "questionOne",
    question: "components.about.us.faq.question.1",
    answer: "components.about.us.faq.answer.1",
  },
  {
    id: "questionTwo",
    question: "components.about.us.faq.question.2",
    answer: "components.about.us.faq.answer.2",
  },
  {
    id: "questionThree",
    question: "components.about.us.faq.question.3",
    answer: "components.about.us.faq.answer.3",
  },
  {
    id: "questionFour",
    question: "components.about.us.faq.question.4",
    answer: "components.about.us.faq.answer.4",
  },
  {
    id: "questionFive",
    question: "components.about.us.faq.question.5",
    answer: "components.about.us.faq.answer.5",
  },
];

export const tableServiceData = [
  {
    description: "components.service.implantology.allInFour",
    germany: "14.000 €",
    england: "12,000 £",
    austria: "10.000 €",
    italy: "9.000 €",
    switzerland: "17'000 ₣",
    albania: "3.000 €",
  },
  {
    description: "components.service.implantology.allInSix",
    germany: "17.000 €",
    england: "16,000 £",
    austria: "12.000 €",
    italy: "11.000 €",
    switzerland: "21'000 ₣",
    albania: "5.000 €",
  },
  {
    description: "components.service.implantology.allInEight",
    germany: "20.000 €",
    england: "20,000 £",
    austria: "14.000 €",
    italy: "13.000 €",
    switzerland: "25'000 ₣",
    albania: "6.000 €",
  },
  {
    description: "components.service.implantology.dentalImplants",
    germany: "2.000 €",
    england: "1,700 £",
    austria: "1.500 €",
    italy: "1.500 €",
    switzerland: "3'000 ₣",
    albania: "400 €",
  },
  {
    description: "components.service.cosmeticDentistry.veneers",
    germany: "1.000 €",
    england: "700 £",
    austria: "800 €",
    italy: "450 €",
    switzerland: "1'450 ₣",
    albania: "300 €",
  },
];
