import React from "react";
import { useTranslation } from "react-i18next";
import { tableServiceData } from "../../shared/utils";
import "./_priceComparison.scss";

const PriceComparisonTable = () => {
  const { t } = useTranslation();

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            <th>{t("components.navbar.services")}</th>
            <th className="al" />
            <th className="de" />
            <th className="en" />
            <th className="au" />
            <th className="it" />
            <th className="ch" />
          </tr>
        </thead>
        <tbody>
          {tableServiceData.map((data, index) => (
            <tr key={index}>
              <td>{t(data.description)}</td>
              <td>{data.albania}</td>
              <td>{data.germany}</td>
              <td>{data.england}</td>
              <td>{data.austria}</td>
              <td>{data.italy}</td>
              <td>{data.switzerland}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PriceComparisonTable;
