import React from "react";
import "./Faq.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import { FAQ } from "../../shared/utils";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <section
      className="faq-section pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <SectionTitle
          subTitle="FAQ"
          title={t("components.about.us.faq.title")}
        />

        <div className="accordian-area">
          <div className="accordion" id="accordionExample">
            {FAQ.map((item, index) => (
              <div className="accordion-item" key={item.id}>
                <h2 className="accordion-header" id={`heading${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${item.id}`}
                    aria-expanded={index === 0 ? "true" : "false"}
                    aria-controls={item.id}
                  >
                    {t(item.question)}
                  </button>
                </h2>
                <div
                  id={item.id}
                  className={`accordion-collapse collapse ${
                    index === 0 ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">{t(item.answer)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
