import React from "react";
import "./privacy.scss";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <section className="privacy-policy">
      <div className="container">
        {parse(t("components.pricay.policy.content"))}
      </div>
    </section>
  );
};

export default Privacy;
