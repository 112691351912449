import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import Gallery from "../sections/Gallery/Gallery";
import ServiceBanner from "../sections/ServiceBanner/ServiceBanner";
import Symptoms from "../sections/Symptoms/Symptoms";
import Appointment from "../sections/Appointment/Appointment";
import { behaviorSubject } from "../components/Navbar/Menu";
import { findContentById } from "../shared/utils";

const Services = () => {
  const storedValue = localStorage.getItem("id");
  const parsedStoredValue = storedValue ? JSON.parse(storedValue) : null;

  const [selectedValue, setSelectedValue] = useState(parsedStoredValue);
  const [contentValue, setContentValue] = useState();

  useEffect(() => {
    const subscription = behaviorSubject.subscribe((item) => {
      setSelectedValue(item ? item : JSON.parse(localStorage.getItem("id")));
      setContentValue(findContentById(selectedValue));
    });

    return () => {
      subscription.unsubscribe();
      behaviorSubject.next(null);
      window.scrollTo(0, 0);
    };
  }, [selectedValue, contentValue]);

  return (
    <>
      <Navbar />
      <ServiceBanner content={contentValue} />
      {contentValue && contentValue.symptoms && (
        <Symptoms content={contentValue.symptoms} />
      )}
      {contentValue && contentValue.proof && (
        <Gallery content={contentValue.proof} />
      )}
      <Appointment />
      <Footer />
    </>
  );
};

export default Services;
