import React, { useState } from "react";
import "./LanguageSwitcher.scss";
import { useUserContext, languages } from "./../languageContext.js";

function LanguageSwitcher() {
  const { isLanguage, setIsLanguage } = useUserContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOnClick = (code) => {
    setIsLanguage(code);
    setShowDropdown(false);
    localStorage.setItem("language", code);
    window.location.reload(true);
  };

  return (
    <div
      className="languageSwitcher"
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div className="lang">
        <div className={isLanguage}></div>
        {showDropdown && (
          <ul className="dropdown">
            {languages.map(
              (language) =>
                language.code !== isLanguage && (
                  <li
                    key={language.code}
                    onClick={() => handleOnClick(language.code)}
                  >
                    <div value={language.code} className={language.code} />
                  </li>
                )
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default LanguageSwitcher;
