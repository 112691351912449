export const PHONE_NUMBER = "+355 69 44 45 522";

export const ADDRESS = "Rr “Jusuf Vrioni” Tirane 1001, Albania";

export const WORKING_HOUR = "09:00 - 20:00";

export const EMAIL = "customer-care@thedentaltour.com";

export const FACEBOOK_PROFILE = "https://www.facebook.com/profile.php?id=61555789078587";

export const INSTAGRAM_PROFILE = "https://www.instagram.com/thedentaltour/";

export const ModalStatus = {
  ERROR: "error",
  SUCCESS: "success",
};
