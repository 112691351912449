import React from 'react';
import './Expert.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import { BsFillCheckCircleFill } from "react-icons/bs";
import expertImg from '../../assets/expert.jpg';
import { useTranslation } from "react-i18next";

const Expert = () => {
    const { t } = useTranslation();

    return (
        <section className='expert-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-text">
                            <SectionTitle
                                subTitle="EXPERTS IN DENTAL"
                                title={t("components.home.expert.title")}
                                description={t("components.home.expert.subtitle")}
                            />

                            <ul>
                                <li>
                                    <BsFillCheckCircleFill />
                                    {t("components.home.expert.quality.list.one")}
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    {t("components.home.expert.quality.list.two")}
                                </li>
                                <li>
                                    <BsFillCheckCircleFill />
                                    {t("components.home.expert.quality.list.three")}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="expert-img">
                            <img src={expertImg} alt="expert" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Expert;