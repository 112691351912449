import "./Modal.scss";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const ResponseModal = ({ status, displayModal }) => {
  const [show, setShow] = useState(displayModal);
  const { t } = useTranslation();

  return (
    <>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton className={`${status}`}>
          <Modal.Title>{t(`component.modal.${status}.title`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t(`component.modal.${status}.content`)}</Modal.Body>
      </Modal>
    </>
  );
};

export default ResponseModal;
