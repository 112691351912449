import React from 'react';
import './Features.scss';
import PriceComparisonTable from '../../components/PriceComparison/PriceComparison';
import { useTranslation } from "react-i18next";

const Features = () => {
    const { t } = useTranslation();
    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <h2>{t('components.home.priceList.title')}</h2>
            <p>
            {t('components.home.priceList.description')}
            </p>
            <PriceComparisonTable />
        </section>
    );
};

export default Features;