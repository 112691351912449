import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { useTranslation } from "react-i18next";



const Services = () => {

    const { t } = useTranslation();

    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                        <SectionTitle title={t("components.home.services.title")} subTitle="Services" />
                        <p className='service-title-text'>{t("components.home.services.subtitle")}</p>
                </div>

                <div className="row">
                    {
                        ServicesData.map((singleService, index) => <Service key={index} serviceList={singleService} />)
                    }
                </div>
            </div>
        </section>
    );
};

export default Services;