import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Navbar from "../../components/Navbar/Navbar";
import "./Contactus.scss";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../sections/Footer/Footer";
import { useTranslation } from "react-i18next";

const Contactus = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="section-bg section-common contact-section">
        <Navbar />
        <SectionTitle
          title={t("components.appointment.title")}
          description={t("components.contact.form.description")}
        />
      </section>
      <section
        className="contact-form-area"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <ContactForm />
      </section>
      <Footer />
    </>
  );
};

export default Contactus;
