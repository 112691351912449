import React from "react";
import "./Appointment.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { AiFillHome } from "react-icons/ai";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useTranslation } from "react-i18next";
import { ADDRESS } from "../../shared/configuration";

const Appointment = () => {
  const { t } = useTranslation();

  const mapLink =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.5130189836955!2d19.803346812190988!3d41.31945650008572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350318d24520e6f%3A0xe99d1b9e3fe41253!2sManjani%20Dental%20(Turismo%20Dentale)!5e0!3m2!1sen!2s!4v1708260890020!5m2!1sen!2s";

  return (
    <section
      className="appointment-section pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="google-map">
              <iframe title="map" src={mapLink}></iframe>

              <div className="location-name">
                <AiFillHome />
                <p>{ADDRESS}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="appointment-form-area">
              <SectionTitle
                title={t("components.appointment.title")}
                description={t("components.appointment.description")}
              />

              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
