import React from 'react';
import { Link } from 'react-router-dom';
import './AboutBanner.scss';
import bannerOne from '../../assets/about/banner/banner_1.png'
import bannerTwo from '../../assets/about/banner/banner_2.png'
import pattern from '../../assets/banner/pattern.png'
import { useTranslation } from 'react-i18next';

const AboutBanner = () => {
    const { t } = useTranslation();

    return (
        <section className='about-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-banner-text">
                                    <h2>{t("components.navbar.about.us")}</h2>
                                    <p>{t("components.about.us.banner.description")}</p>

                                    <div className="theme-btn">
                                        <Link to='/'>{t("components.about.contact.us")}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-banner-img">
                                    <img src={bannerOne} alt="about banner" />
                                    <img src={bannerTwo} alt="about banner two" />
                                    <img className='pattern' src={pattern} alt="about banner two" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBanner;