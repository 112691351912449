import React, { useEffect, useRef, useState } from "react";
import "./Navbar.scss";
import DentalTour from "./../../assets/DentalTour.png";
import { Link } from "react-router-dom";
import { MainMenu } from "./Menu";
import { navbarItems } from "../../shared/utils";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnClickMenu = (menuValue) => {
    menuValue && closeMenu();
  };

  const handleOnClickLogo = () => {
    localStorage.setItem("id", undefined);
  };

  return (
    <div className="main-nav">
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* Logo */}
            <Link className="navbar-brand" to="/" onClick={handleOnClickLogo}>
              <img src={DentalTour} alt="The Dental Tour" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen ? "true" : "false"}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
              id="navbarSupportedContent"
              ref={menuRef}
            >
              {/* Menu Item */}
              <MainMenu
                menuItems={navbarItems}
                handleOnClickMenu={(menuValue) => handleOnClickMenu(menuValue)}
              />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
