import iconOne from '../../assets/service/guide-all-on-4.jpg';
import iconTwo from '../../assets/service/all-on-6.jpg';
import iconThree from '../../assets/service/dental-implants.jpg';
import iconFour from '../../assets/service/veners.jpg';

const ServicesData = [
    {
        'icon': iconOne,
        'title': 'components.service.implantology.allInFour',
        'path': "/allInFour",
    },
    {
        'icon': iconTwo,
        'title': 'components.service.implantology.allInSix',
        'path': "/allInSix",
    },
    {
        'icon': iconThree,
        'title': 'components.service.implantology.dentalImplants',
        'path': "/dentalImplants",
    },
    {
        'icon': iconFour,
        'title': 'components.service.cosmeticDentistry.veneers',
        'path': "/veneers",
       
    }
]

export default ServicesData;