import "./App.scss";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Services from "./pages/Services";
import Contactus from "./pages/Contact/Contactus";
import { IntlProvider } from "react-intl";
import { getMessages } from "./components/LanguageSwitcher/index.js";
import React, { useState } from "react";
import { UserContextProvider } from "./components/LanguageSwitcher/languageContext.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";

function App() {
  const [language, setLanguage] = useState(localStorage.getItem("language"));
  const value = { language, setLanguage };
  const messages = getMessages(language);

  return (
    <UserContextProvider value={{ value }}>
      <IntlProvider locale={language} messages={messages}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/:id" element={<Services />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
      </IntlProvider>
    </UserContextProvider>
  );
}

export default App;
