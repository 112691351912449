import React from "react";
import "./Team.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import doctor1 from "../../assets/about/team/doctor1.jpeg";
import doctor2 from "../../assets/about/team/doctor2.jpg";
import doctor3 from "../../assets/about/team/doctor3.jpg";
import doctor4 from "../../assets/about/team/doctor4.jpg";
import doctor5 from "../../assets/about/team/doctor5.jpg";
import doctor6 from "../../assets/about/team/doctor6.jpg";
import doctor7 from "../../assets/about/team/doctor7.jpg";

const Team = () => {
  const { t } = useTranslation();

  const teams = [
    {
      img: doctor1,
      name: "Denis Haxhialiu",
    },
    {
      img: doctor2,
      name: "Zhoel Manjani",
    },
    {
      img: doctor3,
      name: "Sokol Manjani",
    },
    {
      img: doctor4,
      name: "Ornela Hoxha",
    },
    {
      img: doctor5,
      name: "Ardi Skendaj",
    },
    {
      img: doctor6,
      name: "Denisa Braimllari",
    },
    {
      img: doctor7,
      name: "Eriona Duka",
    },
  ];

  return (
    <section
      className="team-section"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <div className="row">
          <SectionTitle
            subTitle="Meet our Team"
            title={t("components.about.us.team.title")}
          />
          <p>{t("components.about.us.team.description")}</p>
        </div>
        <div
          className="row"
          style={{
            height: "250px",
            overflowX: "scroll",
            display: "flex",
            width: "100%",
          }}
        >
          <div className="scrollable-container">
            <div className="scrollable-photos">
              {teams.map((photo, index) => (
                <div key={index} className="photo-wrapper">
                  <img key={index} src={photo.img} alt={`Photo ${index}`} />
                  <h5>{photo.name}</h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
