import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Appointment from "../sections/Appointment/Appointment";
import Banner from "../sections/Banner/Banner";
import PackageIncluded from "../sections/PackageIncluded/PackageIncluded";
import Expert from "../sections/Expert/Expert";
import Features from "../sections/Features/Features";
import Footer from "../sections/Footer/Footer";
import Services from "../sections/Services/Services";
import Testimonial from "../sections/Testimonial/Testimonial";

const Home = () => {

  return (
    <>
      <Navbar />
      <Banner />
      <Services />
      <Features />
      <PackageIncluded />
      <Expert />
      <Testimonial />
      <Appointment />
      <Footer />
    </>
  );
};

export default Home;
