// import React from "react";
// import "./ServiceBanner.scss";
// import { useTranslation } from "react-i18next";

// const ServiceBanner = ({ content }) => {
//   const language = localStorage.getItem("language");
//   const { t } = useTranslation();
//   let image = "";

//   if (content && content.image) {
//     if (
//       language !== "en" &&
//       ["allInEight", "allInSix", "allInFour"].includes(content.image)
//     ) {
//       image = `/${content.image}_${language}.png`;
//     } else {
//       image = `/${content.image}.png`;
//     }
//   }

//   return (
//     <section className="service-banner-section section-common section-bg">
//       <div className="d-table">
//         <div className="d-table-cell">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-5 col-md-6">
//                 <div
//                   className="service-banner-text"
//                   data-aos="fade-up"
//                   data-aos-duration="2000"
//                 >
//                   {content && <h2 className="p-3">{t(content.title)}</h2>}
//                   {content && <p>{t(content.description)}</p>}
//                 </div>
//               </div>
//               <div
//                 className="col-lg-7 col-md-6"
//                 style={{
//                   paddingTop:
//                     content &&
//                     content.css &&
//                     content.css.paddingTop &&
//                     content.css.paddingTop,
//                 }}
//               >
//                 <div
//                   className="service-banner-img"
//                   data-aos="fade-up"
//                   data-aos-duration="2000"
//                 >
//                   {image && (
//                     <img
//                       src={image}
//                       alt="service"
//                       style={{
//                         maxHeight: "560px",
//                         width: content && content.css && content.css.width,
//                         height: content && content.css && content.css.height,
//                       }}
//                     />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ServiceBanner;


import React, { useEffect, useState } from "react";
import "./ServiceBanner.scss";
import { useTranslation } from "react-i18next";

const ServiceBanner = ({ content }) => {
  const language = localStorage.getItem("language");
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let image = "";

  if (content && content.image) {
    if (
      language !== "en" &&
      ["allInEight", "allInSix", "allInFour"].includes(content.image)
    ) {
      image = `/${content.image}_${language}.png`;
    } else {
      image = `/${content.image}.png`;
    }
  }

  const applyContentCss = content && content.css && !isMobile;

  return (
    <section className="service-banner-section section-common section-bg">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <div
                  className="service-banner-text"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {content && <h2 className="p-3">{t(content.title)}</h2>}
                  {content && <p>{t(content.description)}</p>}
                </div>
              </div>
              <div
                className="col-lg-7 col-md-6"
                style={{
                  paddingTop: applyContentCss ? content.css.paddingTop : undefined,
                }}
              >
                <div
                  className="service-banner-img"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  {image && (
                    <img
                      src={image}
                      alt="service"
                      style={{
                        maxHeight: "560px",
                        marginLeft: content.css.marginLeft,
                        width: applyContentCss ? content.css.width : "250px",
                        height: applyContentCss ? content.css.height : "250px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceBanner;
