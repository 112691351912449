import React from 'react';
import { Link } from 'react-router-dom';
import ThemeIcon from '../ThemeIcon/ThemeIcon';
import './Service.scss';
import { useTranslation } from "react-i18next";
const Service = ({serviceList}) => {
    const { t } = useTranslation();
    const {title, icon,path} = serviceList;
    const hanldeOnSave= ()=>{
        localStorage.setItem('id',JSON.stringify(path.substring(1,path.length)));
    };
    return (
        
        <div className='col-lg-3 col-md-4 col-sm-6'>
             <Link to={path} onClick={hanldeOnSave} className="text-style"   >
           <div className="service-box">
                <div className="service-icon">
                    <ThemeIcon className="theme-icon" icon={icon} />
                </div>
                <div className="service-text">
                    <h3>{t(title)}</h3>
                </div>
           </div>
           </Link>
        </div>
       
    );
};

export default Service;