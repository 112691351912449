import React, { useState, useEffect } from "react";
import "./ContactForm.scss";
import icon from "../../assets/banner/icons/Calling.png";
import { services } from "../../shared/utils";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ResponseModal from "./../Modal/Modal";
import { ModalStatus, PHONE_NUMBER } from "../../shared/configuration";
import axios from "axios";
import * as Yup from "yup";

const phoneRegExp =
  /^(\+\d{1,3}|\(\d{3,5}\)|\d{1,2})\s?(\(\d{1,3}\)|\d{1,4})?\s?\d{2,4}(\s?-?\d{2,4}){1,3}$/;

const nameRegex = /^[a-zA-Z]+(?:[' -][a-zA-Z]+)*$/;

const ContactForm = () => {
  const language = localStorage.getItem("language");
  const { t } = useTranslation();

  const validationMessages = {
    en: {
      name: {
        invalid: "Invalid name format.",
        min: "Name must be at least {{min}} characters long.",
        max: "Name must be at most {{max}} characters long.",
        required: "Name is required.",
      },
      email: {
        invalid: "Invalid email address.",
        required: "Email is required.",
      },
      phone: {
        invalid: "Invalid phone number format.",
        required: "Phone number is required.",
      },
    },
    it: {
      name: {
        invalid: "Formato nome non valido.",
        min: "Il nome deve contenere almeno {{min}} caratteri.",
        max: "Il nome deve contenere al massimo {{max}} caratteri.",
        required: "Il nome è obbligatorio.",
      },
      email: {
        invalid: "Indirizzo email non valido.",
        required: "L'email è obbligatoria.",
      },
      phone: {
        invalid: "Formato numero di telefono non valido.",
        required: "Il numero di telefono è obbligatorio.",
      },
    },
    de: {
      name: {
        invalid: "Ungültiges Namensformat.",
        min: "Name muss mindestens {{min}} Zeichen lang sein.",
        max: "Name darf höchstens {{max}} Zeichen lang sein.",
        required: "Name ist erforderlich.",
      },
      email: {
        invalid: "Ungültige E-Mail-Adresse.",
        required: "E-Mail ist erforderlich.",
      },
      phone: {
        invalid: "Ungültiges Telefonnummerformat.",
        required: "Telefonnummer ist erforderlich.",
      },
    },
    sq: {
      name: {
        invalid: "Formati i emrit është i pavlefshëm.",
        min: "Emri duhet të jetë të paktën {{min}} karaktere i gjatë.",
        max: "Emri duhet të jetë më së shumti {{max}} karaktere i gjatë.",
        required: "Emri është i detyrueshëm.",
      },
      email: {
        invalid: "Adresa e email-it është e pavlefshme.",
        required: "Email-i është i detyrueshëm.",
      },
      phone: {
        invalid: "Formati i numrit të telefonit është i pavlefshëm.",
        required: "Numri i telefonit është i detyrueshëm.",
      },
    },
  };

  const generateErrorMessages = () => {
    const messages = validationMessages[language] || validationMessages["en"];
    return Yup.object().shape({
      name: Yup.string()
        .matches(nameRegex, messages.name.invalid)
        .min(2, messages.name.min)
        .max(100, messages.name.max)
        .required(messages.name.required),
      email: Yup.string()
        .email(messages.email.invalid)
        .required(messages.email.required),
      phone: Yup.string().matches(phoneRegExp, messages.phone.invalid),
    });
  };

  useEffect(() => {
    setValidationSchema(generateErrorMessages());
  }, [language]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [validationSchema, setValidationSchema] = useState(() =>
    generateErrorMessages()
  );

  const initialValues = JSON.parse(localStorage.getItem("formData")) || {
    name: "",
    email: "",
    phone: "",
    service: t("components.navbar.services.implantology"),
    message: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(
        "https://contact.thedentaltour.com/api/v1/contactUs",
        values
      );
      if (response.status === 200) {
        setShowSuccessModal(true);
        setShowErrorModal(false);
        setTimeout(() => setShowSuccessModal(false), 5000);
      }
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setShowErrorModal(true);
        setShowSuccessModal(false);
        setTimeout(() => setShowErrorModal(false), 5000);
      }
    }
    resetForm();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t("components.contact.form.name")}</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t("components.contact.form.name")}
                  name="name"
                />
                <ErrorMessage className="error" name="name" component="div" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t("components.contact.form.email")}</label>
                <Field
                  type="email"
                  className="form-control"
                  placeholder={t("components.contact.form.email")}
                  name="email"
                />
                <ErrorMessage className="error" name="email" component="div" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t("components.contact.form.phone.number")}</label>
                <Field
                  type="text"
                  className="form-control"
                  placeholder={t("components.contact.form.phone.number")}
                  name="phone"
                />
                <ErrorMessage className="error" name="phone" component="div" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>{t("components.contact.form.service")}</label>
                <Field as="select" className="form-control" name="service">
                  {services.map((service) => (
                    <option key={service.id}>{t(service.label)}</option>
                  ))}
                </Field>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">
                  {t("components.contact.form.message")}
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  placeholder={t("components.contact.form.message")}
                  rows="2"
                  name="message"
                />
                <ErrorMessage
                  className="error"
                  name="message"
                  component="div"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <button
                type="submit"
                className="btn appointment-btn"
                disabled={isSubmitting}
              >
                {t("components.about.contact.us")}
              </button>
            </div>
            <div className="col-lg-6">
              <div className="appointment-call">
                <div className="icon">
                  <img src={icon} alt="icon" />
                </div>
                <div className="call-text">
                  <p>{t("components.contact.form.emergency")}</p>
                  <h6>{PHONE_NUMBER}</h6>
                </div>
              </div>
            </div>
          </div>
          {showSuccessModal && (
            <ResponseModal status={ModalStatus.SUCCESS} displayModal={true} />
          )}
          {showErrorModal && (
            <ResponseModal status={ModalStatus.ERROR} displayModal={true} />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
