import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../sections/Footer/Footer";
import Privacy from "../components/PrivayPolicy/Privacy";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
