import React, { useState, useEffect, useRef } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BehaviorSubject } from "rxjs";
import LanguageSwitcher from "../LanguageSwitcher/json/LanguageSwitcher";

const RecursiveMenu = ({ items, onMenuItemClick }) => {
  const { t } = useTranslation();

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          {item.submenu ? (
            <NavDropdown title={t(item.name)} id="nav-dropdown" drop="end">
              <RecursiveMenu
                items={item.submenu}
                onMenuItemClick={onMenuItemClick}
              />
            </NavDropdown>
          ) : (
            <Link
              to={item.path}
              className="dropdown-item"
              onClick={() => onMenuItemClick(item.id)}
            >
              {t(item.name)}
            </Link>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

const behaviorSubject = new BehaviorSubject();

const MainMenu = ({ menuItems, handleOnClickMenu }) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [firstDropdownClick, setFirstDropdownClick] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navRef = useRef(null);

  const handleDropdownClick = () => {
    if (firstDropdownClick) {
      setDropdownOpen(!dropdownOpen);
    } else {
      setShowDropdown(!showDropdown);
    }
    setFirstDropdownClick(false);
  };

  const handleMenuItemClick = (itemID) => {
    behaviorSubject.next(itemID);
    localStorage.setItem("id", JSON.stringify(itemID));
    setShowDropdown(false);
    setFirstDropdownClick(true);
    setDropdownOpen(false);
    window.scrollTo(0, 0);
    handleOnClickMenu(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowDropdown(false);
        setFirstDropdownClick(true);
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Nav ref={navRef}>
      {menuItems.map((menuItem, index) => (
        <React.Fragment key={index}>
          {menuItem.submenu ? (
            <NavDropdown
              title={t(menuItem.name)}
              id="nav-dropdown"
              show={showDropdown || dropdownOpen}
              onClick={handleDropdownClick}
              onToggle={() => setDropdownOpen(!dropdownOpen)}
            >
              <RecursiveMenu
                items={menuItem.submenu}
                onMenuItemClick={handleMenuItemClick}
              />
            </NavDropdown>
          ) : (
            <Link
              className="nav-link"
              to={menuItem.path}
              onClick={() => handleMenuItemClick(menuItem.id)}
            >
              {t(menuItem.name)}
            </Link>
          )}
        </React.Fragment>
      ))}
      <LanguageSwitcher />
    </Nav>
  );
};

export { MainMenu, behaviorSubject };
