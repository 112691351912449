import React from 'react';
import './IconList.scss';

const IconList = ({title, description}) => {
    return (
        <div>
            <div className="icon-list">
                
                <div className="icon-list-text">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default IconList;