import React from "react";
import "./Testimonial.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import imgOne from "../../../node_modules/material-letter-icons/dist/png/A.png";
import imgTwo from "../../../node_modules/material-letter-icons/dist/png/A.png";
import imgThree from "../../../node_modules/material-letter-icons/dist/png/O.png";
import TestimoniCard from "../../components/TestimoniCard/TestimoniCard";
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation();

    const testimonails = [
        {
            'img': imgOne,
            'name': 'Arjan Doda',
            'description' : 'Thank you very much to clinika Manjani dental,and thanks to Dr Denis and Dr Jona , all the staf was amazing, wish them all the best',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgTwo,
            'name': 'Andi Lala',
            'description' : 'Welcoming staff and really professional dental services! Highly recommend the clinic for your next dental appointment! 🦷🪥 …',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgThree,
            'name': 'Ornela Veizaj',
            'description' : 'Pa diskutim me te miret.',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgOne,
            'name': 'Amarildo Kodracaj',
            'description' : 'Best experience ever! Exellent staff',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        },
        {
            'img': imgOne,
            'name': 'Abele Albore',
            'description' : 'Grande competenza, ottimo team, grande Amico. grazie  Denis 👋👋 …',
            'ratings':[<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>,<AiFillStar/>]
        }
    ]

  // slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      className="testimonail-section section-bg section-common pt-100 pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <div className="row">
          <SectionTitle title={t("components.testimonial.title")} />
          <p>{t("components.testimonial.description")}</p>
        </div>
        <Slider {...settings} className="testimoni-slider">
          {testimonails.map((testimonail, index) => (
            <TestimoniCard testimonail={testimonail} key={index} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonial;
