import React, { createContext, useState, useContext, useEffect } from "react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importing translation files

import translationEN from "../../i18n/en.json";
import translationDE from "../../i18n/de.json";
import translationIT from "../../i18n/it.json";
import translationAL from "../../i18n/al.json";
import { DEFAULT_LOCALE } from ".";

//Languages
const languages = [
  { code: "en", name: "English" },
  { code: "de", name: "Deutsch" },
  { code: "it", name: "Italiano" },
  { code: "al", name: "Shqip" },
];

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  al: {
    translation: translationAL,
  },
};

// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [isLanguage, setIsLanguage] = useState(() => {
    const browserLanguage = navigator.language.slice(0, 2);
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || browserLanguage || DEFAULT_LOCALE;
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (!storedLanguage) {
      const browserLanguage = navigator.language.slice(0, 2);
      setIsLanguage(browserLanguage || DEFAULT_LOCALE);
    }
  }, [isLanguage]);

  i18n.use(initReactI18next).init({
    resources,
    lng: isLanguage, //browser lang
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={{ isLanguage, setIsLanguage }}>
      {children}
    </UserContext.Provider>
  );
};

// context consumer hook
const useUserContext = () => {
  // get the context
  const context = useContext(UserContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }

  return context;
};

export { UserContext, UserContextProvider, useUserContext, i18n, languages };
