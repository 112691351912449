import React from "react";
import DentalTourWhite from "../../assets/DentalTourWhite.png";
import "./Footer.scss";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import call from "../../assets/footer/calling.png";
import time from "../../assets/footer/time.png";
import location from "../../assets/footer/location.png";
import email from "../../assets/footer/email.png";
import {
  ADDRESS,
  EMAIL,
  PHONE_NUMBER,
  WORKING_HOUR,
  INSTAGRAM_PROFILE,
  FACEBOOK_PROFILE,
} from "../../shared/configuration";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  const footerContacts = [
    {
      title: t("components.contact.form.phone.number"),
      info: PHONE_NUMBER,
      icon: call,
    },
    {
      title: t("components.footer.open.hour"),
      info: WORKING_HOUR,
      icon: time,
    },
    {
      title: t("components.footer.clinic.address"),
      info: ADDRESS,
      icon: location,
    },
    { title: t("components.contact.form.email"), info: EMAIL, icon: email },
  ];

  return (
    <footer className="pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-5">
            <div className="footer-logo">
              <img src={DentalTourWhite} alt="logo" />
            </div>
            <p>{t("components.footer.moto")}</p>

            <div className="social-logo">
              <p>{t("components.footer.follow.us")}</p>
              <ul>
                <li>
                  <a href={FACEBOOK_PROFILE}>
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href={INSTAGRAM_PROFILE}>
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-2"></div>
          <div className="col-lg-4 col-md-5">
            <div className="footer-contact">
              <p>{t("components.footer.information")}</p>

              {footerContacts.map((footerContact, index) => {
                return (
                  <div className="contact-list" key={index}>
                    <div className="contact-icon">
                      <img src={footerContact.icon} alt="call" />
                    </div>
                    <div className="contact-text">
                      <p>{footerContact.title}</p>
                      <h5>{footerContact.info}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="copy-text">
            <p>&copy; The Dental Tour, All Right Reserved</p>
          </div>
          <div className="copy-links">
            <ul>
              {/* <li>
                <Link to="/">{t("components.footer.terms.use")}</Link>
              </li> */}
              <li>
                <Link to="/privacy">
                  {t("components.footer.privacy.policy")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
