import React from "react";
import "./Symptoms.scss";
import IconList from "../../components/IconList/IconList";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Symptoms = ({ content }) => {
  const { title, description, signs } = content;
  const { t } = useTranslation();

  return (
    <section
      className="symptoms-section section-common pt-100 pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <SectionTitle title={t(title)} description={t(description)} />

        <div>
          {signs.map((singleSymptoms) => (
            <IconList
              key={singleSymptoms.title}
              icon={singleSymptoms.icon}
              title={parse(t(singleSymptoms.title))}
              description={parse(t(singleSymptoms.description))}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Symptoms;
